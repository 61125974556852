.notification-enter {
  opacity: 0.01;
}

.notification-enter.notification-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.notification-exit {
  opacity: 1;
}

.notification-exit.notification-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

#overlay-root {
  transition: background-color 0.3s ease-in-out;
}

#overlay-root:not(:empty) {
  transition: background-color 0.3s ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.light #overlay-root:not(:empty) {background-color: rgba(255, 255, 255, 0.8);}
.dark #overlay-root:not(:empty) {background-color: rgba(83, 83, 83, 0.8);}
html {
 height: 100%
}

body{
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
}

#root{
  display: flex;
  height: 100%;
  width: 100%
}

.main {
  height: 100%;
  width: 100%;
  /* * New concept implementation */
  /* display: flex; */
  /* flex-direction: column; */
}
.main .container {
  word-wrap: break-word;
}
/*DX CUSTOM*/
.dx-button-has-text .dx-icon.fa {
  font-size: 15px !important;
}
.dx-form .dx-field-item-label {
  font-weight: 500;
}
.toolbar-header-custom {
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
}
.dx-list .dx-empty-message {
  white-space: normal;
  word-wrap: normal;
}
.dx-list .dx-list-item.dx-state-focused div {
  color: inherit !important;
}
.dx-datagrid-rowsview.dx-empty {
  height: 30px;
}
.dx-form-group-with-caption > .dx-form-group-content {
  border-top: 1px solid #f2f2f2;
  /*border: none;*/
}
.dx-form-group-with-caption .dx-form-group-content {
  padding-top: 13px;
  padding-bottom: 3px;
}
.dx-form-group-caption {
  font-size: 14px;
  text-transform: uppercase;
}
.dx-box-item-content {
  width: 100% !important;
  /*We need it for dxLookup, because it expand box by width, issue in because it use width: auto;*/
  /*!important for override inline styles */
}

.dx-validationsummary-item-content {
     border-bottom: 0px;
     cursor: auto;
}

/*CUSTOM FORMS*/
.form-signIn
{
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 22px 3px;
  max-width: 280px;
  text-align: center;
  
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.form-register .form-control,
.form-setupconnection .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-register .form-control:focus,
.form-setupconnection .form-control:focus {
  z-index: 2;
}

.dx-overlay-wrapper.dx-dropdowneditor-overlay, 
.dx-overlay-content {
  z-index: 1111111 !important; /*over fabric*/
}

/*TODO*/
.list .dx-list-item-content{
  padding: 10px 0px 10px 0px;
}
.linked-pane .dx-list-item,
.linked-pane .dx-list .dx-empty-message {
  border-top: none;
}

.dx-list .list-item-title {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dx-texteditor, .dx-button {
  border-radius: 3px !important;
}


.ql-editor {
  min-height: 18em;
}

.ms-dialogMainHideOverride {
  display: none !important;
}

/*separator*/
.separator {
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  color: #c3c7d9;
  margin: 30px 0 23px;
}
.separator-line {
  flex: 1 0 auto;
  height: 1px;
  background: #c3c7d9;
}

.separator-text {
  padding: 0 10px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
i[data-icon-name="ProgressRingDots"] {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.datesDialogFooter {
  margin-top: 20px;
}

.breadCrumbSingleItem div {
  font-weight: normal !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  footer#footer {
    display: none;
  }
}

.dx-toolbar .dx-texteditor {
  width: 157px;
}
.dx-scrollview-scrollbottom-text {
  margin-bottom: 8px;
}
.dx-loadindicator-content {
  box-sizing: border-box;
    border-radius: 50%;
    border-width: 1.5px;
    border-style: solid;
    border-color: rgb(0, 120, 212) rgb(199, 224, 244) rgb(199, 224, 244);
    border-image: initial;
    animation-name: css-382;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
    width: 28px;
    height: 28px;
}
@keyframes css-382 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.dx-loadindicator-icon {
  display: none !important;
}
.ms-ContextualMenu-Callout {
  max-width: 80%;
}
.ms-ContextualMenu-list.is-open  {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
}
.ms-Callout-Beak {
  right: 258px;
}
.ms-Breadcrumb-overflowButton {
  margin-left: 8px;
}

.item-loader {
  height: 1px;
}
.item-loader .ms-ProgressIndicator-itemProgress{
  padding: 0px !important;
  height: 1px;
}
